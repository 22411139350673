import { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
  setArticles, 
  setError,
  setKnowledgeGraph,
  setOrganicResults,
  setPeopleAlsoAsk,
  setPlaces,
  setRelatedSearches,
  setTopStories,
  clearSearchResults,
  clearDynamicPodcasts,
  setIsFeedlyContent,
  setLoading,
  setSearchType,
  setSearchParameters,
  clearPdfFile,
  selectPdfFile,
  selectComposeParameters,
  selectComposeInstructions
} from '../../../redux/store';

const API_BASE_URL = process.env.NODE_ENV === 'development' 
  ? 'http://localhost:3000' 
  : 'https://backend.podsi.xyz';

const MAX_RETRIES = 3;

// Helper function to normalize item format for compose
const normalizeItem = (item, type) => {
  return {
    ...item,
    id: item.id || item.link || Math.random().toString(36).substr(2, 9),
    source: item.source || (item.link ? new URL(item.link).hostname : 'Unknown Source'),
    type: type,
    date: item.date,
    imageUrl: item.imageUrl || null,
    title: item.title || 'Untitled',
    link: item.link || item.url,
    description: item.snippet || item.description,
    content: item.snippet || item.content || item.description
  };
};

// Helper function to normalize article format for other search types
const normalizeArticle = (article, type) => {
  return {
    ...article,
    id: article.id || article.link || Math.random().toString(36).substr(2, 9),
    source: article.source || (article.link ? new URL(article.link).hostname : 'Unknown Source'),
    type: type,
    date: article.date,
    imageUrl: article.imageUrl || null,
    title: article.title || 'Untitled',
    link: article.link || article.url,
    description: article.snippet || article.description,
    content: article.snippet || article.content || article.description
  };
};

// Helper function to process compose search results
const processComposeResults = (data, dispatch) => {
  if (!data.searchResults) return;
  
  const { organic, news, topStories } = data.searchResults;

  // Process and normalize organic results
  const normalizedOrganic = (organic || []).map(item => 
    normalizeItem(item, 'organic')
  );

  // Process and normalize news results
  const normalizedNews = (news || []).map(item => 
    normalizeItem(item, 'news')
  );

  // Combine and deduplicate items based on URL
  const seenUrls = new Set();
  const combinedItems = [...normalizedNews, ...normalizedOrganic]
    .filter(item => {
      if (seenUrls.has(item.link)) return false;
      seenUrls.add(item.link);
      return true;
    });

  // Set combined items as main list
  dispatch(setArticles(combinedItems));

  // Set organic results separately for reference
  if (organic?.length > 0) {
    dispatch(setOrganicResults(normalizedOrganic));
  }
};

export const useSearch = (isFeedlyContent, feedlyToken, categoryLabel) => {
  const dispatch = useDispatch();
  const queryInitiatedRef = useRef(null);
  const pdfFile = useSelector(selectPdfFile);
  const composeParameters = useSelector(selectComposeParameters);
  const composeInstructions = useSelector(selectComposeInstructions);

  const [loadingArticles, setLoadingArticles] = useState(false);
  const [articlesLoaded, setArticlesLoaded] = useState(false);
  const [podcastGenerationIds, setPodcastGenerationIds] = useState({});
  const [retryCount, setRetryCount] = useState(0);
  const [lastQuery, setLastQuery] = useState(null);

  useEffect(() => {
    setLoadingArticles(false);
    setArticlesLoaded(false);
    setPodcastGenerationIds({});
    setRetryCount(0);
    setLastQuery(null);
    queryInitiatedRef.current = null;
  }, [isFeedlyContent]);

  const fetchArticlesAndInitiatePodcasts = useCallback(async (query) => {
    const searchParams = new URLSearchParams(window.location.search);
    const searchType = searchParams.get('searchType') || 'news';

    dispatch(setSearchType(searchType));

    const queryKey = isFeedlyContent
      ? `${categoryLabel}-feedly`
      : searchType === 'pdf' && pdfFile
        ? `${pdfFile.name}-pdf`
        : searchType === 'compose'
          ? `${query}-compose`
          : `${query}-${searchType}-search`;

    if (queryInitiatedRef.current === queryKey || retryCount >= MAX_RETRIES) {
      return;
    }

    try {
      setLoadingArticles(true);
      dispatch(setLoading(true));
      queryInitiatedRef.current = queryKey;

      let endpoint;
      let requestConfig = {
        headers: {
          'Content-Type': 'application/json'
        }
      };

      // Handle special cases first
      if (isFeedlyContent) {
        endpoint = `${API_BASE_URL}/api/v1/feedly/generateAndRespondFeedly`;
        requestConfig.headers['Authorization'] = `Bearer ${feedlyToken}`;
        
        const urlParams = new URLSearchParams();
        urlParams.append('q', query || '');
        urlParams.append('count', '10');
        if (categoryLabel) urlParams.append('category', categoryLabel);
        endpoint = `${endpoint}?${urlParams.toString()}`;
      } else if (searchType === 'url') {
        endpoint = `${API_BASE_URL}/api/v1/url/initiate`;
        requestConfig = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ url: query })
        };
      } else if (searchType === 'pdf') {
        endpoint = `${API_BASE_URL}/api/v1/podcast/initiate`;
        if (pdfFile) {
          dispatch(clearSearchResults());
          dispatch(clearDynamicPodcasts());
          
          const formData = new FormData();
          formData.append('pdf', pdfFile);
          requestConfig = {
            method: 'POST',
            body: formData
          };
        } else {
          throw new Error('No PDF file found');
        }
      } else if (searchType === 'compose') {
        endpoint = `${API_BASE_URL}/api/v1/compose-podcast`;
        requestConfig = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            instructions: composeInstructions,
            type: composeParameters.type,
            audience: composeParameters.audience,
            duration: composeParameters.duration,
            tone: composeParameters.tone
          })
        };
      } else {
        // For news, organic, and local searches, validate first
        const validateResponse = await fetch(`${API_BASE_URL}/api/v1/validate-dynamic?q=${encodeURIComponent(query)}`);
        if (!validateResponse.ok) {
          throw new Error('Search validation failed');
        }
        const validationData = await validateResponse.json();

        // Determine validated search term based on search type
        let validatedQuery;
        if (searchType === 'news') {
          validatedQuery = validationData.data.google_news_search_term;
        } else {
          validatedQuery = validationData.data.is_google_search.search_term;
        }

        // Set endpoint based on search type with validated query
        switch (searchType) {
          case 'organic':
            endpoint = `${API_BASE_URL}/api/v1/regular/dynamic/initiate`;
            const organicParams = new URLSearchParams();
            organicParams.append('q', validatedQuery);
            organicParams.append('count', '10');
            endpoint = `${endpoint}?${organicParams.toString()}`;
            break;
          case 'local':
            endpoint = `${API_BASE_URL}/api/v1/regular/local/initiate`;
            const localParams = new URLSearchParams();
            localParams.append('q', validatedQuery);
            localParams.append('count', '10');
            localParams.append('location', searchParams.get('location'));
            endpoint = `${endpoint}?${localParams.toString()}`;
            break;
          default:
            endpoint = `${API_BASE_URL}/api/v1/podcasts/search/dynamic-news/initiate`;
            const defaultParams = new URLSearchParams();
            defaultParams.append('q', validatedQuery);
            defaultParams.append('count', '10');
            endpoint = `${endpoint}?${defaultParams.toString()}`;
        }
      }

      const response = await fetch(endpoint, requestConfig);

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
      }

      const data = await response.json();
      
      if (!data.success) {
        throw new Error(data.error || 'Failed to load content');
      }

      console.log('API Response:', {
        searchType,
        dataKeys: Object.keys(data),
        data
      });

      // Handle top stories consistently across all endpoints
      if (data.searchResults?.topStories) {
        dispatch(setTopStories(data.searchResults.topStories));
      } else if (data.topStories) {
        dispatch(setTopStories(data.topStories));
      }

      if (data.results?.searchParameters) {
        dispatch(setSearchParameters(data.results.searchParameters));
      }

      if (searchType === 'compose') {
        processComposeResults(data, dispatch);
        
        if (data.generationIds) {
          setPodcastGenerationIds({
            mainPodcast: data.generationIds.main,
            topNewsPodcast: data.generationIds.topNews,
            conversationalPodcast: data.generationIds.conversational,
            trendingPodcast: data.generationIds.trending,
            topicOverviewPodcast: data.generationIds.topicOverview
          });
        }
      } else if (searchType === 'organic' && data.results) {
        if (data.results.organic) {
          dispatch(setOrganicResults(data.results.organic));
          dispatch(setArticles(data.results.organic));
        }

        if (data.results.topStories) {
          dispatch(setTopStories(data.results.topStories));
        }

        if (data.results.knowledgeGraph) {
          dispatch(setKnowledgeGraph(data.results.knowledgeGraph));
        }

        if (data.results.places) {
          dispatch(setPlaces(data.results.places));
        }

        if (data.results.peopleAlsoAsk) {
          dispatch(setPeopleAlsoAsk(data.results.peopleAlsoAsk));
        }

        if (data.results.relatedSearches) {
          dispatch(setRelatedSearches(data.results.relatedSearches));
        }

        if (data.podcasts) {
          console.log('Setting podcastGenerationIds for organic search:', data.podcasts);
          setPodcastGenerationIds(data.podcasts);
        } else if (data.generationId) {
          setPodcastGenerationIds({
            topNewsPodcast: data.generationId,
            trendingNewsPodcast: data.generationId,
            topicOverviewPodcast: data.generationId,
            conversationalPodcast: data.generationId
          });
        }
      } else {
        const articles = data.articles || data.news || [];
        dispatch(setArticles(articles));
        
        if (data.knowledgeGraph) {
          dispatch(setKnowledgeGraph(data.knowledgeGraph));
        }

        if (data.places && searchType === 'local') {
          dispatch(setPlaces(data.places));
        }

        if (data.peopleAlsoAsk) {
          dispatch(setPeopleAlsoAsk(data.peopleAlsoAsk));
        }

        if (data.relatedSearches) {
          dispatch(setRelatedSearches(data.relatedSearches));
        }

        if (data.podcasts) {
          console.log('Setting podcastGenerationIds:', data.podcasts);
          setPodcastGenerationIds(data.podcasts);
        } else if (data.generationId) {
          setPodcastGenerationIds({
            topNewsPodcast: data.generationId,
            trendingNewsPodcast: data.generationId,
            topicOverviewPodcast: data.generationId,
            conversationalPodcast: data.generationId
          });
        }
      }

      setArticlesLoaded(true);
      setRetryCount(0);
      setLastQuery(searchType === 'pdf' ? pdfFile.name : query);

      if (searchType === 'pdf') {
        dispatch(clearPdfFile());
      }
    } catch (error) {
      console.error('Error fetching content:', error);
      queryInitiatedRef.current = null;

      if (error.message.includes('401')) {
        dispatch(setError('Authentication failed. Please try logging in again.'));
      } else if (error.message.includes('429')) {
        dispatch(setError('Too many requests. Please try again later.'));
      } else {
        dispatch(setError(error.message || 'Failed to load content'));
        setRetryCount(prev => prev + 1);
      }
    } finally {
      setLoadingArticles(false);
      dispatch(setLoading(false));
    }
  }, [dispatch, isFeedlyContent, categoryLabel, feedlyToken, retryCount, pdfFile, composeParameters, composeInstructions]);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search).get('q');
    const searchType = new URLSearchParams(window.location.search).get('searchType');
    
    if (searchType === 'pdf' && isFeedlyContent) {
      dispatch(clearSearchResults());
      dispatch(clearDynamicPodcasts());
      dispatch(setIsFeedlyContent(false));
    }
    
    if (isFeedlyContent && categoryLabel) {
      setLastQuery(categoryLabel);
      fetchArticlesAndInitiatePodcasts(query);
      return;
    }

    if (searchType === 'pdf' && pdfFile) {
      fetchArticlesAndInitiatePodcasts(null);
      return;
    }

    if (!isFeedlyContent && query && query !== lastQuery) {
      setLastQuery(query);
      fetchArticlesAndInitiatePodcasts(query);
    }
  }, [fetchArticlesAndInitiatePodcasts, isFeedlyContent, categoryLabel, lastQuery, pdfFile]);

  const retryFetch = useCallback(() => {
    const query = new URLSearchParams(window.location.search).get('q');
    queryInitiatedRef.current = null;
    setRetryCount(0);
    setArticlesLoaded(false);
    setPodcastGenerationIds({});
    fetchArticlesAndInitiatePodcasts(query);
  }, [fetchArticlesAndInitiatePodcasts]);

  return {
    loadingArticles,
    articlesLoaded,
    podcastGenerationIds,
    isRetrying: retryCount > 0,
    retryCount,
    retryFetch,
    hasError: retryCount >= MAX_RETRIES
  };
};

export default useSearch;