import { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
  setError, 
  setAudioUrl, 
  setPlayingState,
  setAudioDuration,
  setAudioTime,
  selectCurrentTime,
  selectDuration,
  selectCurrentPodcast,
  selectIsPlaying,
  selectAudioUrl,
  resetAudioState
} from '../../../redux/store';

const API_BASE_URL = process.env.NODE_ENV === 'development' 
  ? 'http://localhost:3000' 
  : 'https://backend.podsi.xyz';

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

export const useLoadingAudio = () => {
  const dispatch = useDispatch();
  
  // Selectors
  const currentPodcast = useSelector(selectCurrentPodcast);
  const isPlaying = useSelector(selectIsPlaying);
  const audioUrl = useSelector(selectAudioUrl);
  const currentTime = useSelector(selectCurrentTime);
  const duration = useSelector(selectDuration);
  
  // State management
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState('idle');
  const [generationId, setGenerationId] = useState(null);
  const [backgroundMusicPlaying, setBackgroundMusicPlaying] = useState(false);
  
  // Refs
  const backgroundAudioRef = useRef(new Audio());
  const retryAttemptsRef = useRef(0);
  const pendingTransitionRef = useRef(null);
  const transitionInProgressRef = useRef(false);
  const fadeAnimationFrameRef = useRef(null);
  
  // Transition queue system
  const transitionQueue = useRef({
    previewComplete: false,
    mainPodcastReady: false,
    mainPodcastData: null
  });

  const MAX_RETRIES = 3;

  // Audio event handlers
  const handleAudioPlay = useCallback(() => {
    console.log('Background music started playing');
    setBackgroundMusicPlaying(true);
  }, []);

  const handleAudioPause = useCallback(() => {
    console.log('Background music paused');
    setBackgroundMusicPlaying(false);
  }, []);

  const handleAudioEnded = useCallback(() => {
    console.log('Background music ended');
    setBackgroundMusicPlaying(false);
  }, []);

  const handleAudioError = useCallback((error) => {
    const bgAudio = backgroundAudioRef.current;
    if (bgAudio?.src && !bgAudio.src.includes('localhost:3001/results')) {
      console.error('Background audio error:', error);
      console.error('Audio state:', {
        src: bgAudio.src,
        readyState: bgAudio.readyState,
        networkState: bgAudio.networkState,
        error: bgAudio.error
      });
    }
    setBackgroundMusicPlaying(false);
  }, []);

  const handleAudioStateChange = useCallback(() => {
    const bgAudio = backgroundAudioRef.current;
    console.log('Audio state changed:', {
      readyState: bgAudio.readyState,
      networkState: bgAudio.networkState,
      currentTime: bgAudio.currentTime,
      duration: bgAudio.duration,
      paused: bgAudio.paused,
      ended: bgAudio.ended
    });
  }, []);

  // Helper to format audio URLs
  const formatAudioUrl = useCallback((url) => {
    if (!url) return null;
    if (url.startsWith('http')) return url;
    // Ensure proper URL formatting with audio directory
    return `${API_BASE_URL}/audio/${url.replace(/^\/+/, '').replace(/^(assets\/)?audio\//, '')}`;
  }, [API_BASE_URL]);

  // Background music cleanup
  const cleanupBackgroundAudio = useCallback(() => {
    const bgAudio = backgroundAudioRef.current;
    if (bgAudio) {
      if (bgAudio.handleTimeUpdate) {
        bgAudio.removeEventListener('timeupdate', bgAudio.handleTimeUpdate);
      }
      if (isMobile && bgAudio.handleMobileEnded) {
        bgAudio.removeEventListener('ended', bgAudio.handleMobileEnded);
      } else {
        bgAudio.removeEventListener('ended', handleAudioEnded);
      }
      bgAudio.removeEventListener('play', handleAudioPlay);
      bgAudio.removeEventListener('pause', handleAudioPause);
      bgAudio.removeEventListener('error', handleAudioError);
      bgAudio.removeEventListener('loadedmetadata', handleAudioStateChange);
      bgAudio.removeEventListener('canplay', handleAudioStateChange);
      bgAudio.removeEventListener('stalled', handleAudioStateChange);
      bgAudio.pause();
      bgAudio.currentTime = 0;
      bgAudio.src = '';
    }

    if (fadeAnimationFrameRef.current) {
      cancelAnimationFrame(fadeAnimationFrameRef.current);
      fadeAnimationFrameRef.current = null;
    }
  }, [handleAudioPlay, handleAudioPause, handleAudioEnded, handleAudioError, handleAudioStateChange]);

  // Setup background audio event listeners
  const setupBackgroundAudio = useCallback(() => {
    const bgAudio = backgroundAudioRef.current;
    
    if (isMobile) {
      console.log('Setting up mobile background audio');
      bgAudio.volume = 0.2;
      // Add mobile-specific attributes
      bgAudio.preload = 'auto';
      bgAudio.playsinline = true;
      bgAudio.setAttribute('webkit-playsinline', 'true');
      bgAudio.loop = true;
    } else {
      console.log('Setting up desktop background audio');
    }
    
    // Define handleTimeUpdate within setupBackgroundAudio
    const handleTimeUpdate = () => {
      // Only use custom looping for desktop
      if (!isMobile && bgAudio.duration - bgAudio.currentTime < 0.2) {
        bgAudio.currentTime = 0;
      }
    };
    
    bgAudio.addEventListener('timeupdate', handleTimeUpdate);
    bgAudio.addEventListener('play', handleAudioPlay);
    bgAudio.addEventListener('pause', handleAudioPause);
    bgAudio.addEventListener('ended', handleAudioEnded);
    bgAudio.addEventListener('error', handleAudioError);
    bgAudio.addEventListener('loadedmetadata', handleAudioStateChange);
    bgAudio.addEventListener('canplay', handleAudioStateChange);
    bgAudio.addEventListener('stalled', handleAudioStateChange);

    // Store handleTimeUpdate in a ref so we can access it during cleanup
    bgAudio.handleTimeUpdate = handleTimeUpdate;
  }, [handleAudioPlay, handleAudioPause, handleAudioEnded, handleAudioError, handleAudioStateChange]);

  // Initialize background audio on mount
  useEffect(() => {
    setupBackgroundAudio();
    return cleanupBackgroundAudio;
  }, [setupBackgroundAudio, cleanupBackgroundAudio]);

  // Define fadeOutBackground before attemptTransition
  const fadeOutBackground = useCallback(async (duration = 2000) => {
    return new Promise((resolve) => {
      const bgAudio = backgroundAudioRef.current;
      if (!bgAudio || !backgroundMusicPlaying) {
        resolve();
        return;
      }

      console.log('Starting background fade out');
      const startVolume = bgAudio.volume;
      const startTime = performance.now();
      
      const fadeStep = () => {
        const currentTime = performance.now();
        const elapsed = currentTime - startTime;
        const progress = Math.min(elapsed / duration, 1);
        
        if (progress < 1) {
          bgAudio.volume = startVolume * (1 - progress);
          fadeAnimationFrameRef.current = requestAnimationFrame(fadeStep);
        } else {
          cleanupBackgroundAudio();
          setBackgroundMusicPlaying(false);
          console.log('Background fade out complete');
          resolve();
        }
      };

      fadeAnimationFrameRef.current = requestAnimationFrame(fadeStep);
    });
  }, [backgroundMusicPlaying, cleanupBackgroundAudio]);

  const handleMobilePlayback = async (backgroundConfig) => {
    const bgAudio = backgroundAudioRef.current;
    
    // Mobile-specific setup
    bgAudio.preload = 'auto';
    bgAudio.playsinline = true;
    bgAudio.setAttribute('webkit-playsinline', 'true');
    bgAudio.volume = backgroundConfig.mobileVolume || 0.2;
    
    // Mobile-specific playback logic here
    try {
      await bgAudio.load();
      await new Promise(resolve => setTimeout(resolve, 300)); // Mobile needs longer delay
      await bgAudio.play();
    } catch (error) {
      console.error('Mobile playback failed:', error);
      throw error;
    }
  };

  const handleDesktopPlayback = async (backgroundConfig) => {
    const bgAudio = backgroundAudioRef.current;
    
    // Desktop-specific setup
    bgAudio.volume = backgroundConfig.volume || 0.3;
    
    // Desktop-specific playback logic here
    try {
      await bgAudio.load();
      await new Promise(resolve => setTimeout(resolve, 100));
      await bgAudio.play();
    } catch (error) {
      console.error('Desktop playback failed:', error);
      throw error;
    }
  };

  const controlBackgroundMusic = useCallback(async (backgroundConfig, action = 'play') => {
    console.log('Control background music:', { action, config: backgroundConfig });
    
    if (action === 'play' && backgroundConfig) {
      try {
        cleanupBackgroundAudio();
        // Add small delay to ensure cleanup is complete
        await new Promise(resolve => setTimeout(resolve, 100));
        
        const bgAudio = backgroundAudioRef.current;
        const fullBackgroundUrl = formatAudioUrl(backgroundConfig.url);
        
        console.log('Playing background music from:', fullBackgroundUrl);
        
        setupBackgroundAudio();
        
        bgAudio.src = fullBackgroundUrl;

        if (isMobile) {
          // Mobile-specific setup
          bgAudio.preload = 'auto';
          bgAudio.playsinline = true;
          bgAudio.setAttribute('webkit-playsinline', 'true');
          bgAudio.volume = backgroundConfig.mobileVolume || 0.2;
          bgAudio.loop = true;
          
          try {
            await bgAudio.load();
            await new Promise(resolve => setTimeout(resolve, 300));
            await bgAudio.play();
            setBackgroundMusicPlaying(true);
            console.log('Background music started successfully');
          } catch (playError) {
            console.error('Playback failed:', playError);
            handleAudioError(playError);
          }
        } else {
          // Keep desktop exactly as it was in your original code
          bgAudio.volume = backgroundConfig.volume || 0.3;
          bgAudio.loop = true;

          // Separate try/catch for playback
          try {
            await bgAudio.load();
            await bgAudio.play();
            setBackgroundMusicPlaying(true);
            console.log('Background music started successfully');
          } catch (playError) {
            console.error('Playback failed:', playError);
            handleAudioError(playError);
          }
        }
      } catch (error) {
        console.error('Background music initialization error:', error);
        handleAudioError(error);
      }
    } else if (action === 'stop') {
      await fadeOutBackground();
    }
  }, [API_BASE_URL, fadeOutBackground, cleanupBackgroundAudio, setupBackgroundAudio, handleAudioError, formatAudioUrl]);

  // Attempt transition when conditions are met
  const attemptTransition = useCallback(async () => {
    const queue = transitionQueue.current;
    
    if (transitionInProgressRef.current) {
        console.log('Transition already in progress');
        return;
    }

    console.log('Checking transition conditions:', {
        previewComplete: queue.previewComplete,
        mainPodcastReady: queue.mainPodcastReady,
        hasMainPodcastData: !!queue.mainPodcastData
    });

    if (!queue.previewComplete || !queue.mainPodcastReady || !queue.mainPodcastData) {
        console.log('Waiting for all conditions to be met');
        return;
    }

    try {
        console.log(`Starting transition to main podcast on ${isMobile ? 'mobile' : 'desktop'}`);
        transitionInProgressRef.current = true;

        const { audioUrl: mainAudioUrl, podcast } = queue.mainPodcastData;

        if (backgroundMusicPlaying) {
            console.log('Fading out background music before transition');
            // Shorter fade for mobile
            await fadeOutBackground(isMobile ? 1000 : 2000);
        }

        dispatch(resetAudioState());
        
        console.log('Loading main podcast audio:', mainAudioUrl);
        const tempAudio = new Audio(mainAudioUrl);
        
        await new Promise((resolve, reject) => {
            tempAudio.addEventListener('loadedmetadata', () => {
                console.log('Main podcast metadata loaded, duration:', tempAudio.duration);
                dispatch(setAudioDuration(tempAudio.duration));
                dispatch(setAudioUrl(mainAudioUrl, podcast));
                dispatch(setPlayingState(true));
                resolve();
            }, { once: true });

            tempAudio.addEventListener('error', (error) => {
                console.error('Main podcast load error:', error);
                reject(error);
            }, { once: true });

            tempAudio.load();
        });

        setStatus('complete');
        console.log('Main podcast transition completed successfully');

    } catch (error) {
        console.error('Transition error:', error);
        dispatch(setError('Failed to start main podcast'));
    } finally {
        cleanupBackgroundAudio();
        transitionQueue.current = {
            previewComplete: false,
            mainPodcastReady: false,
            mainPodcastData: null
        };
        transitionInProgressRef.current = false;
    }
  }, [dispatch, backgroundMusicPlaying, fadeOutBackground, cleanupBackgroundAudio]);

  // Monitor preview completion
  useEffect(() => {
    if (currentPodcast?.type === 'loading' && 
        currentTime > 0 && 
        duration > 0 && 
        currentTime >= (duration - 0.1) && 
        !transitionQueue.current.previewComplete) {
      console.log('Preview completed, currentTime:', currentTime, 'duration:', duration);
      transitionQueue.current.previewComplete = true;
      attemptTransition();
    }
  }, [currentTime, duration, currentPodcast, attemptTransition]);

  const stopLoadingAudio = useCallback(() => {
    console.log('Stopping loading audio');
    
    if (pendingTransitionRef.current) {
      clearTimeout(pendingTransitionRef.current);
      pendingTransitionRef.current = null;
    }

    transitionQueue.current = {
      previewComplete: false,
      mainPodcastReady: false,
      mainPodcastData: null
    };
    transitionInProgressRef.current = false;

    controlBackgroundMusic(null, 'stop');
    setStatus('idle');
  }, [controlBackgroundMusic]);

  const playLoadingAudio = useCallback(async (podcast) => {
    try {
      console.log('Starting loading audio for podcast:', podcast.title);
      setIsLoading(true);
      setStatus('generating');
      
      transitionQueue.current = {
        previewComplete: false,
        mainPodcastReady: false,
        mainPodcastData: null
      };
      transitionInProgressRef.current = false;
      stopLoadingAudio();
      retryAttemptsRef.current = 0;
      dispatch(resetAudioState());

      const requestBody = {
        podcastType: podcast.type,
        podcastTitle: podcast.title,
        contentType: podcast.anecdote ? 'anecdote' : 'announcement',
        categories: podcast.categories || ['Technology'],
        anecdote: podcast.anecdote
      };

      console.log('Sending PlayAux request:', requestBody);

      const generateResponse = await fetch(`${API_BASE_URL}/api/v1/playaux/generate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody)
      });

      if (!generateResponse.ok) {
        const errorText = await generateResponse.text();
        console.error('PlayAux error response:', errorText);
        throw new Error('Failed to generate loading audio');
      }

      const { generationId, data } = await generateResponse.json();
      console.log('Loading audio response:', data);
      
      setGenerationId(generationId);

      const loadingPodcast = {
        id: `loading_${generationId}`,
        title: `Loading: ${podcast.title}`,
        type: 'loading',
        imageUrl: podcast.imageUrl,
        audioUrl: data.content.url
      };

      if (data.background?.url) {
        await controlBackgroundMusic(data.background);
      }

      dispatch(setAudioUrl(data.content.url, loadingPodcast));
      if (data.content.duration) {
        console.log('Setting loading audio duration:', data.content.duration);
        dispatch(setAudioDuration(data.content.duration));
      }
      dispatch(setPlayingState(true));

      setStatus('playing');
      console.log('Loading audio started successfully');
      return data;

    } catch (error) {
      console.error('Error playing loading audio:', error);
      dispatch(setError('Failed to play loading audio'));
      setStatus('error');
      throw error;
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, controlBackgroundMusic, stopLoadingAudio]);

  const setMainPodcastReady = useCallback((audioUrl, podcast) => {
    console.log('Main podcast ready:', { audioUrl, podcastTitle: podcast.title });
    
    transitionQueue.current.mainPodcastReady = true;
    transitionQueue.current.mainPodcastData = { audioUrl, podcast };
    attemptTransition();
  }, [attemptTransition]);

// Cleanup when component unmounts (continued)
useEffect(() => {
  return () => {
    cleanupBackgroundAudio();
    
    if (pendingTransitionRef.current) {
      clearTimeout(pendingTransitionRef.current);
    }
    
    if (generationId) {
      fetch(`${API_BASE_URL}/api/v1/playaux/stop`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ podcastId: generationId })
      }).catch(console.error);
    }

    // Reset queue and transition state
    transitionQueue.current = {
      previewComplete: false,
      mainPodcastReady: false,
      mainPodcastData: null
    };
    transitionInProgressRef.current = false;
  };
}, [generationId, cleanupBackgroundAudio]);

// Monitor background audio state changes
useEffect(() => {
  const bgAudio = backgroundAudioRef.current;
  
  const handleVolumeChange = () => {
    console.log('Background volume changed:', bgAudio.volume);
  };

  const handleTimeUpdate = () => {
    if (backgroundMusicPlaying) {
      console.log('Background time update:', bgAudio.currentTime);
    }
  };

  bgAudio.addEventListener('volumechange', handleVolumeChange);
  bgAudio.addEventListener('timeupdate', handleTimeUpdate);

  return () => {
    bgAudio.removeEventListener('volumechange', handleVolumeChange);
    bgAudio.removeEventListener('timeupdate', handleTimeUpdate);
  };
}, [backgroundMusicPlaying]);

// Handle window beforeunload
useEffect(() => {
  const handleBeforeUnload = () => {
    cleanupBackgroundAudio();
  };

  window.addEventListener('beforeunload', handleBeforeUnload);
  
  return () => {
    window.removeEventListener('beforeunload', handleBeforeUnload);
  };
}, [cleanupBackgroundAudio]);

return {
  isLoading,
  status,
  backgroundMusicPlaying,
  playLoadingAudio,
  stopLoadingAudio,
  setMainPodcastReady,
};
};

export default useLoadingAudio;