import axios from 'axios';

const API_BASE_URL = process.env.NODE_ENV === 'development' 
  ? 'http://localhost:3000' 
  : 'https://backend.podsi.xyz';

//console.log('API_BASE_URL:', API_BASE_URL);

const formatPublishDate = (publishedTimestamp) => {
  const now = new Date();
  const publishedDate = new Date(publishedTimestamp);
  const diffInMinutes = Math.floor((now - publishedDate) / (1000 * 60));
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInHours / 24);

  if (diffInDays > 0) {
    return publishedDate.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
  } else if (diffInHours > 0) {
    return `${diffInHours} hour${diffInHours > 1 ? 's' : ''} ago`;
  } else {
    return `${diffInMinutes} minute${diffInMinutes > 1 ? 's' : ''} ago`;
  }
};

// For getFeedlyCategories
const getFeedlyCategories = async (token) => {
  console.log('Fetching categories with API_BASE_URL:', API_BASE_URL);
  try {
    const response = await axios.get(`${API_BASE_URL}/api/v1/feedly/categories`, {
      headers: { 
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error in getFeedlyCategories:', error);
    throw error;
  }
};

// For searchFeedlyStream
const searchFeedlyStream = async (token, query) => {
  console.log('Searching stream with API_BASE_URL:', API_BASE_URL);
  try {
    const response = await axios.get(`${API_BASE_URL}/api/v1/feedly/search`, {
      params: { query },
      headers: { 
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error in searchFeedlyStream:', error);
    throw error;
  }
};

// For getFeedlyFeed
const getFeedlyFeed = async (token, query) => {
  console.log('Fetching feed with API_BASE_URL:', API_BASE_URL);
  try {
    const response = await axios.get(`${API_BASE_URL}feedly/feed`, {
      params: { query },
      headers: { 
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error in getFeedlyFeed:', error);
    throw error;
  }
};

// For getFeedlyArticlesByCategory
const getFeedlyArticlesByCategory = async (token, category, count) => {
  console.log('Fetching articles by category with API_BASE_URL:', API_BASE_URL);
  try {
    const validCount = count && !isNaN(count) ? count : 20;
    const response = await axios.get(`${API_BASE_URL}/api/v1/feedly/articles-by-category`, {
      params: { category, count: validCount },
      headers: { 
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error in getFeedlyArticlesByCategory:', error);
    throw error;
  }
};

// For getNews
const getNews = async (token, refresh = false) => {
  console.log('Fetching news with API_BASE_URL:', API_BASE_URL);
  try {
    const response = await axios.post(`${API_BASE_URL}/api/v1/feedly/news/${refresh}`, null, {
      headers: { 
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error in getNews:', error);
    throw error;
  }
};

// For getFeedlyReadLater
const getFeedlyReadLater = async (token, count) => {
  console.log('Fetching read-later articles with API_BASE_URL:', API_BASE_URL);
  try {
    const validCount = count && !isNaN(count) ? count : 20;
    const response = await axios.get(`${API_BASE_URL}/api/v1/feedly/read-later`, {
      params: { count: validCount },
      headers: { 
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error in getFeedlyReadLater:', error);
    throw error;
  }
};

// For tagFeedlyEntry
const tagFeedlyEntry = async (token, entryId, tag) => {
  console.log('Tagging entry with API_BASE_URL:', API_BASE_URL);
  try {
    const response = await axios.post(`${API_BASE_URL}/api/v1/feedly/tag-entry`, 
      { entryId, tag },
      {
        headers: { 
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error in tagFeedlyEntry:', error);
    throw error;
  }
};

// For tagFeedlyReadLater
const tagFeedlyReadLater = async (token, entryId) => {
  console.log('Tagging entry as read later with API_BASE_URL:', API_BASE_URL);
  try {
    const response = await axios.post(`${API_BASE_URL}/api/v1/feedly/tag-read-later`,
      { entryId },
      {
        headers: { 
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error in tagFeedlyReadLater:', error);
    throw error;
  }
};

// For markArticleAsRead
const markArticleAsRead = async (token, trackId) => {
  console.log('Marking article as read with API_BASE_URL:', API_BASE_URL);
  try {
    const response = await axios.post(`${API_BASE_URL}/api/v1/feedly/mark-as-read`,
      { trackId },
      {
        headers: { 
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error in markArticleAsRead:', error);
    throw error;
  }
};

export {
  getFeedlyCategories,
  searchFeedlyStream,
  getFeedlyFeed,
  getFeedlyArticlesByCategory,
  getNews,
  getFeedlyReadLater,
  tagFeedlyEntry,
  tagFeedlyReadLater,
  markArticleAsRead
};
